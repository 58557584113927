// materials.js
const materials = {

  zvetnik: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',

    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
     
    },
    // ... добавьте остальные материалы для тумбы
  ],
  StonePechMaxi: [
    {
      baseColor: '/textures/zmeevkik_01.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',

    },
    {
      baseColor: '/textures/yellow.jpg',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
     
    },
    // ... добавьте остальные материалы для тумбы
  ],
  StoneKryshkaMaxi: [
    {
      baseColor: '/textures/zmeevkik_01.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',

    },
    {
      baseColor: '/textures/yellow.jpg',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
     
    },
    // ... добавьте остальные материалы для тумбы
  ],

  StoneKonvektor: [
    {
      baseColor: '/textures/zmeevkik_01.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',

    },
    {
      baseColor: '/textures/yellow.jpg',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
     
    },
    // ... добавьте остальные материалы для тумбы
  ],
};

export default materials;