// StoneKryshkaMaxi.jsx
import React, { useEffect, useRef, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader, RepeatWrapping, sRGBEncoding, Raycaster, Vector2 } from 'three';
import materials from './materials';

const StoneKryshkaMaxi = ({ StoneKryshkaMaxiMaterialIndex, activeComponent }) => {
  const { scene, camera, gl } = useThree();
  const modelRef = useRef();
  const textureLoader = new TextureLoader();
  const raycaster = useRef(new Raycaster());
  const mouse = useRef(new Vector2());

  useEffect(() => {
    // Загрузка и добавление модели в сцену один раз
    const loader = new GLTFLoader();
    loader.load('/mesh/maxi_kryshka.glb', (gltf) => {
      modelRef.current = gltf.scene;
      scene.add(gltf.scene);

      // Используем activeComponent для определения позиции
      const position = activeComponent === 'oneplace' ? [0, 0, 0] : [0, 0, 0];
      modelRef.current.position.set(...position);

      // Немедленное обновление текстур после загрузки модели
      updateTextures(StoneKryshkaMaxiMaterialIndex);
    });

    return () => {
      // Удаление модели из сцены при размонтировании компонента
      if (modelRef.current) {
        scene.remove(modelRef.current);
      }
    };
  }, [scene, StoneKryshkaMaxiMaterialIndex, activeComponent]);

  const updateTextures = useCallback((materialIndex) => {
    if (!modelRef.current) return;
    const materialConfig = materials.StoneKryshkaMaxi[materialIndex];
    if (!materialConfig) return;

    const textureScale = materialConfig.scale || 2;

    const onLoadTexture = (texture) => {
      texture.encoding = sRGBEncoding;
      texture.wrapS = texture.wrapT = RepeatWrapping;
      texture.repeat.set(textureScale, textureScale);
      texture.needsUpdate = true;
    };

    const baseColorMap = textureLoader.load(materialConfig.baseColor, onLoadTexture);
    const roughnessMap = textureLoader.load(materialConfig.roughness, onLoadTexture);
    const normalMap = textureLoader.load(materialConfig.normalMap, onLoadTexture);

    modelRef.current.traverse((child) => {
      if (child.isMesh) {
        child.material.map = baseColorMap;
        child.material.roughnessMap = roughnessMap;
        child.material.normalMap = normalMap;
        child.material.metalness = materialConfig.metalness || 0.7;
        child.material.needsUpdate = true;
      }
    });
  }, [textureLoader]);

  useEffect(() => {
    const onClick = (event) => {
      const rect = gl.domElement.getBoundingClientRect();
      mouse.current.x = (event.clientX / rect.width) * 2 - 1;
      mouse.current.y = -(event.clientY / rect.height) * 2 + 1;
      raycaster.current.setFromCamera(mouse.current, camera);
      const intersects = raycaster.current.intersectObject(modelRef.current, true);

      if (intersects.length > 0) {
        const intersectedObject = intersects[0].object;
        intersectedObject.visible = !intersectedObject.visible;
      }
    };

    gl.domElement.addEventListener('click', onClick);
    return () => {
      gl.domElement.removeEventListener('click', onClick);
    };
  }, [camera, gl.domElement]);

  return null;
};

export default StoneKryshkaMaxi;
