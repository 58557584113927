import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls, Text } from '@react-three/drei';
import { TextureLoader } from 'three'; // Добавлено TextureLoader
import materials from './materials';
import Background from './Background';
import ColorIndicator from './ColorIndicator';
import StoneKryshkaMaxi from './StoneKryshkaMaxi';

const goBack = () => {
  window.history.back();
};

const App = () => {
  const canvasRef = useRef(); // Добавляем реф для Canvas

  function CameraSetup() {
    const { camera } = useThree();
    useEffect(() => {
      camera.position.set(0, 0.4, 1.3);
      camera.lookAt(2, 5, -8);
    }, [camera]);
    return null;
  }

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState(''); // Состояние для электронной почты

  // Состояние для загруженного изображения
  const [uploadedImage, setUploadedImage] = useState(null);

  // Функция для обработки загрузки изображения
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Создаём URL для загруженного файла
      setUploadedImage(imageUrl);
    }
  };

  const ImagePlane = ({ imageUrl, position }) => {
    const texture = useLoader(TextureLoader, imageUrl);
    return (
      <mesh position={position}>
        <planeBufferGeometry args={[1, 1]} /> {/* Ширина и высота плоскости */}
        <meshBasicMaterial map={texture} />
      </mesh>
    );
  };

  // Состояние для текста (Фамилия)
  const [surname, setSurname] = useState('Фамилия');

  // Функция для обработки изменения текста
  const handleSurnameChange = (e) => {
    setSurname(e.target.value);
  };

  // Компонент для отображения текста в Canvas
  const TextPlane = ({ text, position }) => {
    return (
      <Text
        position={position}
        fontSize={0.1}
        color="white"
        anchorX="center"  // Выравнивание текста по горизонтали
        anchorY="middle"  // Выравнивание текста по вертикали
      >
        {text}
      </Text>
    );
  };

  // Состояния для материалов
  const [StoneKryshkaMaxiMaterialIndex, setStoneKryshkaMaxiMaterialIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState('Pechi');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleNextMaterialStoneKryshkaMaxi = () => {
    setStoneKryshkaMaxiMaterialIndex((prevIndex) => (prevIndex + 1) % materials.StoneKryshkaMaxi.length);
  };

  const handlePrevMaterialStoneKryshkaMaxi = () => {
    setStoneKryshkaMaxiMaterialIndex((prevIndex) => (prevIndex - 1 + materials.StoneKryshkaMaxi.length) % materials.StoneKryshkaMaxi.length);
  };

  const sendEmailWithScreenshot = async (surname, phone, screenshotBlob) => {
    const email = new URLSearchParams(window.location.search).get('email');
    const formData = new FormData();
    formData.append('surname', surname);
    formData.append('phone', phone);
  
    // Генерация уникального имени файла
    const uniqueFileName = `screenshot_${Date.now()}.png`;
    
    // Добавляем файл с уникальным именем
    formData.append('image', screenshotBlob, uniqueFileName); 
  
    const response = await fetch(`https://test.finist-kamen.ru/send_email.php?email=${encodeURIComponent(email)}`, {
      method: 'POST',
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error('Failed to send email');
    }
  
    const result = await response.json();
    console.log(result);
  };

  // Функция для обработки отправки формы
  const handleSubmit = async () => {
    try {
      // Создаем скриншот Canvas
      const canvas = canvasRef.current;
      const screenshotBlob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png');
      });

      // Отправляем данные
      await sendEmailWithScreenshot(surname, phoneNumber, screenshotBlob);
      alert('Данные успешно отправлены!');
    } catch (error) {
      alert('Ошибка отправки данных: ' + error.message);
    }
  };

  return (
    <div className="construktor">
      <Canvas ref={canvasRef} gl={{ preserveDrawingBuffer: true }}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <CameraSetup />
        <OrbitControls enableZoom={true} enablePan={true} />
        <Background hdrBackgroundPath="/textures/bg.hdr" hdrEnvironmentPath="/textures/envMap.hdr" />
        
        {/* Отображение компонента на основе выбранного продукта */}
        {selectedProduct === 'Pechi' && (
          <>
            {/* Если изображение загружено, показываем его */}
            {uploadedImage ? (
              <ImagePlane imageUrl={uploadedImage} position={[0, 0, 0]} />
            ) : (
              <ImagePlane imageUrl="/textures/portret-woman.jpg" position={[0, 0, 0]} /> // Заглушка, если изображение не загружено
            )}
            {/* Отображение текста в Canvas */}
            <TextPlane text={surname} position={[0, 0.3, 0.1]} />
            <StoneKryshkaMaxi 
              StoneKryshkaMaxiMaterialIndex={StoneKryshkaMaxiMaterialIndex}
            />
          </>
        )}
      </Canvas>

      <div className="controls-content">
        {/* Остальной интерфейс 0.000078 m */}
        {selectedProduct === 'Pechi' && (
          <>
            <div className="wraper-plitka">
              <div className="plitka-title">Крышка цвет:</div>
              <div className="plitka-btn-color">
                <button onClick={handlePrevMaterialStoneKryshkaMaxi}>Назад</button>
                <ColorIndicator materialIndex={StoneKryshkaMaxiMaterialIndex} type="StoneKryshkaMaxi" />
                <button onClick={handleNextMaterialStoneKryshkaMaxi}>Вперед</button>
              </div>
            </div>
          </>
        )}

        {/* Добавьте загрузчик изображения */}
        <div className="wraper-plitka">
          <div className="plitka-title">Загрузить изображение:</div>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </div>

        {/* Интерфейс для изменения фамилии */}
        <div className="wraper-plitka">
          <div className="plitka-title">Фамилия:</div>
          <input
            type="text"
            value={surname}
            onChange={handleSurnameChange}
          />
        </div>

        <div className="wraper-plitka">
          <div className="plitka-title">Рассчитать стоимость:</div>
          <input
            className="plitka-phone"
            type="tel"
            name="phone"
            placeholder="Номер телефона"
            pattern="[+]{1}[0-9]{1,3}[ ]?[0-9]{10}"
            title="Формат: +1234567890 или +123 4567890123"
            required
            onChange={handlePhoneChange}
          />
          <button className="plitka-btn-send" onClick={handleSubmit}>Отправить</button>
        </div>
      </div>
    </div>
  );
};

export default App;
